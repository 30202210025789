@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Roboto", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Faustina", serif;
}

/*
@layer base {
    input {
        @apply px-4 py-2 mx-8;
    }
}*/